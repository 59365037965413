import { Table } from 'client/shared/components/table';
import { formatIndicatorValue } from 'client/shared/core/performance-data';
import { BenchmarkFilter, TrackVariable } from 'core';
import React from 'react';
import { CellProps } from 'react-table';

interface Props {
  readonly setSelectedVariableName: (variable: string) => void;
  readonly selectedVariableName: string | null;
  readonly trackVariables: readonly TrackVariable[];
}

export const TrackVariablesTable: React.FC<Props> = (p) => {
  const { selectedVariableName, setSelectedVariableName, trackVariables } = p;
  const missingBenchmarkValue = React.useMemo(() => {
    return trackVariables.some(
      (variable) =>
        !variable.areasData[0].performanceData
          .at(-1)
          ?.benchmarkValues.find(
            (bv) =>
              bv.filter === BenchmarkFilter.DEFAULT &&
              bv.percentileBucketIndex !== null
          )
    );
  }, [trackVariables]);
  return (
    <div className="mb-4">
      <Table<TrackVariable>
        columns={[
          {
            Cell: ({ row }: CellProps<TrackVariable>) => (
              <p className="text-wrap">{row.original.label}</p>
            ),
            Header: 'Data Point',
            width: 150,
          },
          {
            Cell: ({ row }: CellProps<TrackVariable>) => (
              <p>
                {formatIndicatorValue(
                  row.original.areasData[0].performanceData.at(-1)?.value ?? 0,
                  row.original.valueType
                )}{' '}
                {row.original.suffix}
              </p>
            ),
            Header: 'Value',
            width: 100,
          },
          {
            Cell: ({ row }: CellProps<TrackVariable>) => {
              const percentileIdx =
                row.original.areasData[0].performanceData
                  .at(-1)
                  ?.benchmarkValues.find(
                    (bv) => bv.filter === BenchmarkFilter.DEFAULT
                  )?.percentileBucketIndex ?? null;
              return (
                <p>{percentileIdx !== null ? computeGrade(percentileIdx) : 'N/A'}</p>
              );
            },
            Header: 'Grade',
            minWidth: 80,
            width: 80,
          },
        ]}
        data={trackVariables}
        headerClassName="bg-jungle text-white "
        hidePagination
        onRowClick={(variable) => setSelectedVariableName(variable.name)}
        removeZebraStriping
        rowClassName={(row) =>
          row.name === selectedVariableName ? 'bg-gray-10' : ''
        }
      />
      {missingBenchmarkValue && (
        <p>*grade information not found for a data point for this area</p>
      )}
    </div>
  );
};
TrackVariablesTable.displayName = 'TrackVariablesTable';

function computeGrade(percentileIdx: number): string {
  if (percentileIdx > 7) {
    return 'A';
  } else if (percentileIdx > 5) {
    return 'B';
  } else if (percentileIdx > 3) {
    return 'C';
  } else if (percentileIdx > 1) {
    return 'D';
  } else {
    return 'F';
  }
}

import * as React from 'react';
import './styles.scss';
import {
  MaterialIcon,
  MaterialIconName,
  Btn,
  ButtonTypes,
  ActionLink,
} from 'client/shared/components/base';
import { ContentDrawer } from 'client/shared/components/content-drawer';
import { Case, unionOfEnum } from 'core';
import { useMutationInfo } from 'client/shared/containers/mutation';
import { MutationInfos } from 'client/shared/graphql-mutations';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import {
  RESPONDENT_STATE_IN_LOCAL_STORAGE,
  useRespondentDispatch,
} from 'client/respondent/core/reducers/context';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import { useLocalStorage } from 'client/shared/helpers/hooks';
import { CurrentUser } from 'client/respondent/hooks';
import logo from 'client/assets/polco-logo-dark-text.svg';
import safety from './assets/safety.svg';
import { COLORS_GRAY_20_HEX, COLORS_GRAY_30_HEX } from 'client/shared/core/colors';
import { RideSidebarContentsUnauthenticated } from 'client/respondent/shared/components/right-sidebar';
import { RespondentActions } from 'client/respondent/core/reducers/actions';
import {
  NotLoggedInActions,
  NotLoggedInActionStatus,
} from 'client/respondent/core/types';
const baseClass = 'pn-respondent-sidebar';

export enum SidebarType {
  TRANSIENT = 'TRANSIENT',
  PERMANENT = 'PERMANENT',
}
interface PageStructure {
  readonly ariaLabel?: string;
  readonly title: string;
  readonly to: string;
  readonly icon: MaterialIconName;
}

interface SideBarPropsBase {
  readonly respondent: CurrentUser | null;
  readonly isGuest: boolean;
  readonly hideWhyCreateAccountSection?: boolean;
  readonly hideZipCodeSection?: boolean;
  readonly hideAuthButtons?: boolean;
}

export type SideBarProps =
  | Case<SidebarType.PERMANENT, SideBarPropsBase>
  | Case<
      SidebarType.TRANSIENT,
      {
        readonly open: boolean;
        readonly close: () => void;
      } & SideBarPropsBase
    >;

export const SideBarProps = unionOfEnum(SidebarType, {
  ...SidebarType,
}).andType<SideBarProps>();

export const pagesCopy = {
  guest: {
    guest: 'You are in guest mode!',
    feed: 'Guest Feed',
    accountSetup: 'Account Setup',
    login: 'Login',
  },
  loggedIn: {
    hello: 'Hello',
    links: {
      aboveDivider: [
        {
          text: 'Home',
          icon: MaterialIconName.HOME,
          path: ClientUrlUtils.respondent.feed.path(),
        },
        {
          text: 'Feeds',
          icon: MaterialIconName.ARTICLE,
          path: ClientUrlUtils.respondent.subscriptions.path(),
        },
      ],
      belowDivider: {
        account: {
          text: 'Account',
          icon: MaterialIconName.ACCOUNT_CIRCLE,
          path: ClientUrlUtils.respondent.account.path(),
        },
        logOut: { text: 'Log Out', icon: MaterialIconName.SENSOR_DOOR },
      },
    },
  },
  notLoggedIn: {
    welcome: 'Welcome to Polco',
    alreadyHaveAccount: 'Already have an account?',
    login: 'Login',
    createAccount: 'Create Account',
    paragraphs: [
      {
        iconType: 'material',
        icon: MaterialIconName.THUMBS_UP_DOWN,
        outline: true,
        text: 'Your communities need your input on important issues.',
      },
      {
        iconType: 'material',
        icon: MaterialIconName.NOT_LISTED_LOCATION,
        outline: true,
        text: 'Get access to local area insights and decision making data.',
      },
      {
        iconType: 'material',
        icon: MaterialIconName.FAVORITE_BORDER,
        text: "It's free and completely anonymous.",
      },
      {
        iconType: 'svg',
        icon: safety,
        text: 'Your information is completely private and will never be sold.',
      },
    ],
  },
};

// const loggedInpages: readonly PageStructure[] = [
//   {
//     title: pagesCopy.loggedIn.feed,
//     to: ClientUrlUtils.respondent.feed.path(),
//     ariaLabel: 'Polco resident feed',
//   },
//   {
//     title: pagesCopy.loggedIn.subscriptions,
//     to: '/res/subscriptions',
//   },
//   {
//     title: pagesCopy.loggedIn.account,
//     to: '/res/account',
//   },
// ];

const guestPages: readonly PageStructure[] = [
  {
    icon: MaterialIconName.HOME,
    title: pagesCopy.guest.feed,
    to: ClientUrlUtils.respondent.feed.path(),
  },
  {
    icon: MaterialIconName.PERSON_ADD_ALT_1,
    title: pagesCopy.guest.accountSetup,
    to: ClientUrlUtils.respondent.signup.path(),
  },
  {
    icon: MaterialIconName.ACCOUNT_CIRCLE,
    title: pagesCopy.guest.login,
    to: ClientUrlUtils.respondent.login.path(),
  },
];

export const SideBarContentsUnauthenticated: React.FC<
  Pick<SideBarProps, 'hideAuthButtons' | 'type' | 'respondent'>
> = (p) => {
  const history = useHistory();
  const dispatch = useRespondentDispatch();
  return (
    <div
      className={`${baseClass} ${
        p.type === SidebarType.TRANSIENT ? 'mod-no-padding' : ''
      }`}
    >
      <div className="d-flex flex-column">
        <div className="font-weight-bold font-size-lg text-brand-xd pb-4 mb-2">
          {pagesCopy.notLoggedIn.welcome}
        </div>
        {pagesCopy.notLoggedIn.paragraphs.map((para, idx) => {
          return (
            <div className="d-flex flex-row align-items-center pb-3" key={idx}>
              {para.iconType === 'material' ? (
                <MaterialIcon
                  className="pr-2"
                  icon={para.icon}
                  iconType={para.outline ? 'material-icons-outlined' : undefined}
                  style={{ color: '#C7D8A0' }}
                />
              ) : (
                <img
                  alt={`${para.icon} icon`}
                  className="pr-2 pl-1"
                  src={para.icon}
                />
              )}
              <div className="font-size-sm text-gray-50">{para.text}</div>
            </div>
          );
        })}
        {!p.hideAuthButtons && (
          <>
            <div className="align-self-center">
              <Btn
                action={() => {
                  dispatch(
                    RespondentActions.promptRegistration({
                      actionType: NotLoggedInActions.MANUAL_REGISTRATION,
                      redirectLink: `${history.location.pathname}${history.location.search}`,
                      data: {},
                      status: NotLoggedInActionStatus.PRE_REGISTRATION,
                      registrationType: null,
                    })
                  );
                  history.push(ClientUrlUtils.respondent.signup.path());
                }}
                className={`${baseClass}-btn get-started align-self-center`}
                type={ButtonTypes.SECONDARY}
              >
                {pagesCopy.notLoggedIn.createAccount}
              </Btn>
            </div>
            <hr className={`${baseClass}-divider w-100 mt-0`} />
            <div className="font-size-sm text-Gray-50 text-center pb-3">
              {pagesCopy.notLoggedIn.alreadyHaveAccount}
            </div>
            <div className="align-self-center">
              <Btn
                action={() => {
                  dispatch(
                    RespondentActions.promptRegistration({
                      actionType: NotLoggedInActions.MANUAL_REGISTRATION,
                      redirectLink: `${history.location.pathname}${history.location.search}`,
                      data: {},
                      status: NotLoggedInActionStatus.PRE_REGISTRATION,
                      registrationType: null,
                    })
                  );
                  history.push(ClientUrlUtils.respondent.login.path());
                }}
                className={`${baseClass}-btn login align-self-center my-0`}
                type={ButtonTypes.SECONDARY}
              >
                {pagesCopy.notLoggedIn.login}
              </Btn>
            </div>
          </>
        )}
      </div>

      {p.type === SidebarType.TRANSIENT && (
        <>
          <hr className={`${baseClass}-divider w-100`} />
          <RideSidebarContentsUnauthenticated
            className="mb-3 pb-3 "
            respondent={p.respondent}
          />
        </>
      )}
    </div>
  );
};

const AuthenticatedNavLink: React.FC<
  SideBarProps & {
    readonly text: string;
    readonly path: string;
    readonly icon: MaterialIconName;
    readonly currentlySelected: boolean;
  }
> = (props) => {
  return (
    <div
      className={`${baseClass}-nav-link-container ${
        props.currentlySelected && 'bg-brand-xl'
      } w-100 py-2 px-1`}
    >
      <NavLink
        aria-label={`${props.text} Page`}
        className={`${baseClass}-link ${baseClass}-link-anchor d-flex align-items-center`}
        exact
        onClick={() => {
          if (props.type === SidebarType.TRANSIENT) props.close();
        }}
        to={props.path}
      >
        <MaterialIcon
          className="mr-2"
          icon={props.icon}
          iconType="material-icons-outlined"
          style={{
            color: props.currentlySelected ? COLORS_GRAY_30_HEX : COLORS_GRAY_20_HEX,
          }}
        />
        <div
          className={`font-size-sm text-gray-50 ${
            props.currentlySelected ? 'font-weight-bold' : ''
          }`}
        >
          {props.text}
        </div>
      </NavLink>
    </div>
  );
};

export const SidebarContentsAuthenticated: React.FC<SideBarProps> = (props) => {
  const { pathname } = useLocation();
  const localStorage = useLocalStorage();
  const { fn: logout } = useMutationInfo(MutationInfos.logout);
  const respondentName = props.respondent?.user?.respondent?.firstName;
  const { account, logOut } = pagesCopy.loggedIn.links.belowDivider;

  const guestContents = (
    <div>
      {guestPages.map((page, i) => (
        <div className="mt-3 d-flex flex-row" key={i}>
          <AuthenticatedNavLink
            currentlySelected={pathname === page.to}
            icon={page.icon}
            path={page.to}
            text={page.title}
            {...props}
          />
        </div>
      ))}
    </div>
  );
  return (
    <div
      className={`${baseClass} ${
        props.type === SidebarType.TRANSIENT ? 'mod-no-padding' : ''
      }`}
    >
      {props.isGuest ? (
        <div className="d-flex flex-column">
          <div className="font-weight-bold font-size-lg text-brand-xd pb-4 mb-2">
            {pagesCopy.guest.guest}
          </div>
          <nav className="d-flex flex-column justify-content-between">
            {guestContents}
          </nav>
        </div>
      ) : (
        <div className={`d-flex flex-column`}>
          <div className="font-weight-bold font-size-lg text-brand-xd pb-4 mb-2">
            {pagesCopy.loggedIn.hello}
            {respondentName && `, ${respondentName}`}
          </div>
          <div className={`${baseClass}-authenticated-links d-flex flex-column`}>
            <div
              className={`${baseClass}-authenticated-links-sub d-flex flex-column`}
            >
              {pagesCopy.loggedIn.links.aboveDivider.map((link, idx) => {
                return (
                  <div className="d-flex flex-row align-items-center" key={idx}>
                    <AuthenticatedNavLink
                      {...props}
                      currentlySelected={pathname === link.path}
                      icon={link.icon}
                      path={link.path}
                      text={link.text}
                    />
                  </div>
                );
              })}
            </div>
            <hr className={`${baseClass}-divider w-100 my-0`} />
            <div
              className={`${baseClass}-authenticated-links-sub d-flex flex-column`}
            >
              <AuthenticatedNavLink
                {...props}
                currentlySelected={pathname === account.path}
                icon={account.icon}
                path={account.path}
                text={account.text}
              />
              <ActionLink
                action={async () => {
                  await logout();
                  if (localStorage) {
                    localStorage.removeItem(RESPONDENT_STATE_IN_LOCAL_STORAGE);
                  }
                  if (props.type === SidebarType.TRANSIENT) props.close();
                }}
                className="d-flex px-1 py-2 font-size-sm align-items-center pn-respondent-sidebar-link-logout"
                unstyled
              >
                <MaterialIcon
                  className="mr-2"
                  icon={logOut.icon}
                  iconType="material-icons-outlined"
                  style={{
                    color: COLORS_GRAY_20_HEX,
                  }}
                />
                <div className="text-gray-50">
                  {pagesCopy.loggedIn.links.belowDivider.logOut.text}
                </div>
              </ActionLink>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const Sidebar: React.FC<SideBarProps> = (p) => {
  const loggedIn = !!p.respondent?.user?.respondent?.id;

  const sidebarContents = () => {
    if (!loggedIn) {
      return (
        <SideBarContentsUnauthenticated
          hideAuthButtons={p.hideAuthButtons}
          respondent={p.respondent}
          type={p.type}
        />
      );
    } else return <SidebarContentsAuthenticated {...p} />;
  };

  return p.type === SidebarType.TRANSIENT ? (
    <ContentDrawer clickedOutside={p.close} open={p.open} side="left">
      <div className="d-flex flex-column">
        <div className="d-flex flex-row align-items-center mb-3 p-3">
          {loggedIn && (
            <img alt="Polco's Logo" className={`${baseClass}-img`} src={logo} />
          )}
          <div className={`${baseClass}-spacer flex-grow-1`}></div>
          <Btn
            action={p.close}
            ariaControls="content-drawer-menu"
            className="p-0"
            size="small"
            type={ButtonTypes.SEAMLESS}
          >
            <MaterialIcon icon={MaterialIconName.CLOSE} />
          </Btn>
        </div>
        {sidebarContents()}
      </div>
    </ContentDrawer>
  ) : (
    <div className={`${baseClass}-permanent d-flex flex-column h-100`}>
      <h2 className="d-none">Menu</h2>
      {sidebarContents()}
    </div>
  );
};
Sidebar.displayName = 'Sidebar';

import * as QuestionSharedCore from 'client/shared/core/question';
import * as Gql from 'client/shared/graphql-client/graphql-operations.g';
import { ExtractGql, wrap } from 'core';
import { gqlToClient_conditions } from 'client/shared/core/conditions';
import { SelectLanguageTextFunction } from 'client/shared/hooks';
import { errorLogger } from 'client/shared/core/error-handler';
export type GqlQuestion = ExtractGql<
  ExtractGql<
    NonNullable<Gql.RespondentVotingPage['openContentSetBySlug']>,
    'Survey'
  >['contents'][0],
  'Question'
>;

export function gqlToClient_respondent(
  question: GqlQuestion,
  selectLanguageText: SelectLanguageTextFunction
): QuestionSharedCore.QuestionWithExtendedData {
  const type = gqlTypeToClient(question.choiceSet.type);
  const typedData = wrap(() => {
    switch (type) {
      case QuestionSharedCore.QuestionType.MULTIPLE_CHOICE:
        return {
          type,
          choices: question.choiceSet.choices.map((ch) => ({
            id: ch.id,
            label: selectLanguageText(ch.text),
            includeComment: ch.includeComment,
          })),
          maxSelection: question.choiceSet.maxSelection,
          randomizeChoices: question.choiceSet.randomizeChoices,
          dataDictionary: null, // Respondents don't need data dictionary information
          shareableQuestionChoiceSetId:
            question.choiceSet.sharedChoiceSetData?.id ?? null,
        };
      case QuestionSharedCore.QuestionType.POINT_ALLOCATION:
        return {
          type,
          choices: question.choiceSet.choices.map((ch) => ({
            id: ch.id,
            label: selectLanguageText(ch.text),
          })),
          dataDictionary: null, // Respondents don't need data dictionary information
          shareableQuestionChoiceSetId:
            question.choiceSet.sharedChoiceSetData?.id ?? null,
        };
      default:
        return { type };
    }
  });

  const status = gqlStatusToClient(question.schedule.status, question.id);

  return {
    id: question.id,
    title: selectLanguageText(question.title),
    status,
    description: selectLanguageText(question.description) ?? null,
    images: [],
    typedData,
    shortId: null,
    optional: question.optional,
    demographicAttribute: question.demographicAttribute,
    conditions: gqlToClient_conditions(question.conditions),
  };
}

export function gqlTypeToClient(
  c: Gql.QuestionType
):
  | QuestionSharedCore.QuestionType.FREE_TEXT
  | QuestionSharedCore.QuestionType.MULTIPLE_CHOICE
  | QuestionSharedCore.QuestionType.POINT_ALLOCATION {
  switch (c) {
    case Gql.QuestionType.MULTIPLE_CHOICE:
      return QuestionSharedCore.QuestionType.MULTIPLE_CHOICE;
    case Gql.QuestionType.FREE_TEXT:
      return QuestionSharedCore.QuestionType.FREE_TEXT;
    case Gql.QuestionType.POINT_ALLOCATION:
      return QuestionSharedCore.QuestionType.POINT_ALLOCATION;
  }
}

export function gqlStatusToClient(
  s: Gql.QuestionStatus,
  questionId: string
): QuestionSharedCore.QuestionStatus {
  switch (s) {
    case Gql.QuestionStatus.DRAFT:
      return QuestionSharedCore.QuestionStatus.DRAFT;
    case Gql.QuestionStatus.SCHEDULED:
      return QuestionSharedCore.QuestionStatus.SCHEDULED;
    case Gql.QuestionStatus.PUBLISHED:
      return QuestionSharedCore.QuestionStatus.PUBLISHED;
    case Gql.QuestionStatus.CART:
      return QuestionSharedCore.QuestionStatus.CART;
    case Gql.QuestionStatus.CLOSED:
      return QuestionSharedCore.QuestionStatus.CLOSED;
    case Gql.QuestionStatus.ARCHIVED:
      return QuestionSharedCore.QuestionStatus.ARCHIVED;
    case Gql.QuestionStatus.HISTORIC_RECORD:
      return QuestionSharedCore.QuestionStatus.HISTORIC_RECORD;
    case Gql.QuestionStatus.SOFT_DELETED:
      errorLogger.log(`Soft deleted state in client transform. QID: ${questionId}`);
      return QuestionSharedCore.QuestionStatus.SOFT_DELETED;
  }
}

import * as React from 'react';

import {
  ClientPublishingEntityId,
  publishingEntityPrimaryLogoUrl,
} from 'client/shared/core/publishing-entity';
import {
  QuestionChoice,
  QuestionType,
  VotingChoice,
  choiceIdentifier,
} from 'client/shared/core/question';
import {
  AnswerChangeability,
  LoadableCommentsStatus,
} from 'client/shared/core/types';
import {
  MultiLanguageContent,
  SelectLanguageTextFunction,
} from 'client/shared/hooks';
import { ApiDate, Flavor, QuestionSetType, RespondentsSetStatus, wrap } from 'core';
import { compact, concat } from 'lodash';
import {
  FollowPublishingEntity,
  FollowPublishingEntityVariables,
  QuestionType as GqlQuestionType,
  VoteType,
  InteractWithIdea,
  InteractWithIdeaVariables,
  PublishingEntityAssetType,
  RespondentFeedItemActionType,
  RespondentFeedItemStatus,
  RespondentFeed_Item,
  SubscriptionType,
  VoteForQuestion,
  VoteForQuestionVariables,
  InputVoteType,
  Voting,
} from 'client/shared/graphql-client/graphql-operations.g';
import { BannerAction, LinkAction } from '../feed/components/body/feed-card/common';
import {
  ModalErrorData,
  NotLoggedInActionStatus,
  NotLoggedInActions,
  PublishingEntity,
  Type,
  UserLocationWithSource,
  VotingProps,
} from './types';

import balancingActBanner from 'client/assets/balancing-act-banner.svg';
import balancingActLogo from 'client/assets/balancing-act-logo.svg';
import ideaLogo from 'client/assets/community-avatar.svg';
import logo from 'client/assets/polco-logo.svg';
import ideaBanner from 'client/assets/quotes_mosaic.svg';
import {
  MaterialIconName,
  OptionListMenuOptionProps,
} from 'client/shared/components/base';
import { COPY as IDEA_COPY } from 'client/shared/components/idea/copy';
import {
  IdeaActionType,
  mapActionToIcon,
  reportIdea,
  shareIdeaUrl,
} from 'client/shared/components/idea/idea-more-menu';
import { MutationReturn } from 'client/shared/containers/mutation';
import { graphqlCommentToPropsComment } from 'client/shared/core/comments';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import {
  shortenIdeaTitle,
  timeAgo,
  voteTypeToIdeaInteractionAction,
} from 'client/shared/core/resident-inputs';
import { QueryInfos } from 'client/shared/graphql-queries';
import { GraphQLError } from 'graphql';
import { isPolcoGqlError } from '../../shared/graphql-client';
import { FeedItem } from '../feed/components/body';
import { PublisherAlert } from '../feed/pages/feed';
import { convertBadgeType_GqlToClient } from '../graphql-util/transform/badge';
import { CurrentRespondentData, CurrentUser } from '../hooks/use-respondent';
import { RespondentActions } from './reducers/actions';
import { getConfig } from 'client/shared/core/config';
import {
  AUTHN_CONTEXT,
  AuthnVoteSectionProps,
} from 'client/respondent/shared/account/components/authn-vote-section';

export type ClientRespondentId = Flavor<string, 'Respondent'>;
export type ClientUserId = Flavor<string, 'User'>;
export type GraphQLVoting = NonNullable<Voting['openQuestion']>;
export type GraphQLPreviousVote = NonNullable<GraphQLVoting['previousVote']>;

export type GraphQLPropsLoaded = VotingProps.QuestionSetLoaded;

export function parseError(err: GraphQLError): ModalErrorData {
  if (isPolcoGqlError(err)) {
    return {
      title: err.extra.status.toString(),
      description: err.message,
    };
  } else {
    return {
      title: 'SERVER ERROR',
      description: err.message,
    };
  }
}

export function graphqlChoiceToPropsChoice(args: {
  readonly questionType: GraphQLVoting['choiceSet']['type'];
  readonly choice: GraphQLVoting['results'][0];
  readonly index: number;
  readonly selectLanguageText: SelectLanguageTextFunction;
}): VotingChoice {
  const { questionType, choice, index, selectLanguageText } = args;
  const votesCount = wrap(() => {
    switch (questionType) {
      case GqlQuestionType.POINT_ALLOCATION:
        return (choice.votesCount ?? 0) * 10;
      case GqlQuestionType.MULTIPLE_CHOICE:
      case GqlQuestionType.FREE_TEXT:
        return choice.votesCount ?? 0;
    }
  });
  return {
    choice: {
      ...choice.choice,
      text: selectLanguageText(choice.choice.text),
      choiceValue: null,
    },
    result: choice.result || 0,
    votesCount,
    identifier: choiceIdentifier(index),
  };
}

export function graphqlVoteToPropsVote(
  vote: GraphQLPreviousVote | null,
  questionType: QuestionType
): VotingProps.Vote | null {
  if (vote) {
    switch (questionType) {
      case QuestionType.FREE_TEXT:
        return {
          comment: vote.comment,
          upvotedCommentIds: vote.upvotedComments.map((c) => c.id),
          type: QuestionType.FREE_TEXT,
          questionId: vote.id,
        };
      case QuestionType.MULTIPLE_CHOICE:
        if (!vote.choices) return null;
        return {
          choices: vote.choices.map((c) => ({ id: c.questionChoice.id })),
          comment: vote.comment,
          commentByChoice: vote.choices.reduce((acc, curr) => {
            return { ...acc, [curr.questionChoice.id]: vote.comment };
          }, {}), //TODO fix assumption that UI will only show comment for one choice
          upvotedCommentIds: vote.upvotedComments.map((c) => c.id),
          type: QuestionType.MULTIPLE_CHOICE,
          questionId: vote.id,
        };
      case QuestionType.POINT_ALLOCATION:
        if (!vote.choices) return null;
        return {
          choices: vote.choices.map((c) => {
            return {
              id: c.questionChoice.id,
              point: c.allocation,
            };
          }),
          comment: vote.comment,
          upvotedCommentIds: vote.upvotedComments.map((c) => c.id),
          type: QuestionType.POINT_ALLOCATION,
          questionId: vote.id,
        };
      default:
        return null;
    }
  } else {
    return null;
  }
}

//FIXME? is there a less gross way to assert that these types are compatible?
export function getTypedData(
  inProcessVote: VotingProps.Vote | null,
  previousVote: GraphQLPreviousVote | null,
  question: GraphQLVoting
): VotingProps.QuestionTypedData | null {
  const { choiceSet } = question;
  switch (choiceSet.type) {
    case QuestionType.FREE_TEXT: {
      if (!inProcessVote || inProcessVote.type !== QuestionType.FREE_TEXT) {
        return {
          questionType: QuestionType.FREE_TEXT,
          inProcessVote: null,
          previousVote: graphqlVoteToPropsVote(
            previousVote,
            QuestionType.FREE_TEXT
          ) as VotingProps.Vote_FreeText,
        };
      }
      return {
        questionType: QuestionType.FREE_TEXT,
        inProcessVote: inProcessVote,
        previousVote: graphqlVoteToPropsVote(
          previousVote,
          QuestionType.FREE_TEXT
        ) as VotingProps.Vote_FreeText,
      };
    }
    case QuestionType.MULTIPLE_CHOICE: {
      if (!inProcessVote || inProcessVote.type !== QuestionType.MULTIPLE_CHOICE) {
        return {
          questionType: QuestionType.MULTIPLE_CHOICE,
          inProcessVote: null,
          selectedChoices: [],
          maxSelection: question.choiceSet.maxSelection,
          previousVote: graphqlVoteToPropsVote(
            previousVote,
            QuestionType.MULTIPLE_CHOICE
          ) as VotingProps.Vote_MultipleChoice,
        };
      }
      return {
        questionType: QuestionType.MULTIPLE_CHOICE,
        inProcessVote,
        selectedChoices: inProcessVote.choices ?? [],
        maxSelection: choiceSet.maxSelection,
        previousVote: graphqlVoteToPropsVote(
          previousVote,
          QuestionType.MULTIPLE_CHOICE
        ) as VotingProps.Vote_MultipleChoice,
      };
    }
    case QuestionType.POINT_ALLOCATION: {
      if (!inProcessVote || inProcessVote.type !== QuestionType.POINT_ALLOCATION) {
        return {
          questionType: QuestionType.POINT_ALLOCATION,
          inProcessVote: null,
          selectedChoices: choiceSet.choices.map((ch) => ({
            id: ch.id,
            point: 0,
          })),
          previousVote: graphqlVoteToPropsVote(
            previousVote,
            QuestionType.MULTIPLE_CHOICE
          ) as VotingProps.Vote_PointAllocation,
        };
      }
      return {
        questionType: QuestionType.POINT_ALLOCATION,
        inProcessVote,
        selectedChoices:
          inProcessVote.choices.length !== 0
            ? inProcessVote.choices
            : choiceSet.choices.map((ch) => ({
                id: ch.id,
                point: 0,
              })),
        previousVote: graphqlVoteToPropsVote(
          previousVote,
          QuestionType.MULTIPLE_CHOICE
        ) as VotingProps.Vote_PointAllocation,
      };
    }
    default:
      return null;
  }
}

export function graphqlToProps(
  subscriptionType: SubscriptionType | null,
  contextPub: MultiLanguageContent | null,
  contextPubId: ClientPublishingEntityId | null,
  currentRespondent: CurrentUser | null,
  data: Voting,
  inFlight: boolean,
  state: Type,
  shareable: boolean,
  setType: VotingProps.SetType,
  status: RespondentsSetStatus,
  selectLanguageText: SelectLanguageTextFunction
): Omit<GraphQLPropsLoaded, 'events' | 'setData' | 'questionSetId'> {
  type VotingComments = GraphQLVoting['commentsData']['comments'];
  const p = data.openQuestion?.previousVote ?? null;
  const c = data.openQuestion;
  if (!c) {
    throw new Error('Error processing question type');
  }
  const userComment = p ? p.comment : null;
  const fullUserComment:
    | (Omit<VotingComments[0], 'commenter'> & {
        readonly commenter: VotingComments[0]['commenter'] | CurrentRespondentData;
        readonly isOwnComment?: boolean;
      })
    | null =
    currentRespondent?.user?.respondent && p && userComment
      ? {
          ...userComment,
          //FIXME - show all choices they selected?
          commenterChoice: data.openQuestion?.previousVote?.choices
            ? {
                __typename:
                  data.openQuestion.previousVote.choices[0].questionChoice
                    .__typename,
                id: data.openQuestion.previousVote.choices[0].questionChoice.id,
              }
            : null,
          commenter: currentRespondent.user.respondent,
          isOwnComment: true,
        }
      : null;

  const commentsList = compact(
    concat(
      [fullUserComment],
      (c.commentsData.comments ?? []).filter(
        (comment) => comment.id !== fullUserComment?.id
      )
    )
  );
  const commentsFullyLoaded = commentsList.length >= c.commentsData.totalCount;
  const typedData = getTypedData(state.inProcessVote, p, c);

  if (!typedData) {
    throw new Error('Error processing question type');
  }

  return {
    type: VotingProps.VotingPropsType.LOADED,
    status,
    setType,
    repostData:
      contextPubId && contextPubId !== c.questionSet.publishingEntity.id
        ? {
            reposterName: selectLanguageText(contextPub) ?? null,
            reposterId: contextPubId,
          }
        : null,
    respId: currentRespondent?.user?.respondent?.id ?? null,
    id: c.id,
    questionSetSlug: c.questionSet.slug,
    publishingEntity: {
      ...c.questionSet.publishingEntity,
      name: selectLanguageText(c.questionSet.publishingEntity.name),
    },
    answerChangeability: AnswerChangeability.CAN_CHANGE_COMMENT,
    votingInteraction: state.interaction,
    typedData,
    inProcessComment: state.inProcessComment,
    upvoteCommentsOnVote: state.upvoteCommentsOnVote,
    showFullDetails: state.showFullDetails ?? false,
    datePublished: ApiDate.fromApi(c.schedule.openDate),
    title: selectLanguageText(c.title),
    choices: c.results.map((r, i) =>
      graphqlChoiceToPropsChoice({
        questionType: c.choiceSet.type,
        choice: r,
        index: i,
        selectLanguageText,
      })
    ),
    closed:
      !!c.schedule.closeDate &&
      ApiDate.fromApi(c.schedule.closeDate).getTime() < new Date().getTime(), //TODO fix me to use now?
    details: {
      summary: c.summary || undefined,
      description: selectLanguageText(c.description),
    },
    comments: {
      type: inFlight
        ? LoadableCommentsStatus.LOADING
        : commentsFullyLoaded
          ? LoadableCommentsStatus.FULLY_LOADED
          : LoadableCommentsStatus.PARTIALLY_LOADED,
      loadedQuestion: commentsList.map(graphqlCommentToPropsComment),
    },
    currentUserId: currentRespondent?.user?.id ?? null,
    allowGuestRespondents: c.questionSet.allowGuestRespondents,
    preLoginAction: state.preLoginAction,
    subscriptionType,
    isGuest: currentRespondent?.user?.respondent?.guest ?? true,
    isShareable: shareable,
    showConversionPrompts: c.questionSet.showConversionPrompts,
    showVerification:
      !currentRespondent?.user?.respondent?.firstName ||
      !currentRespondent?.user?.respondent?.lastName,
    allowMultipleResponses: c.questionSet.allowMultipleResponses,
  };
}

export function publishingEntityPath(slug: PublishingEntity['slug']): string {
  return ClientUrlUtils.respondent.pubProfile.path({ slug });
}

export function publishingEntityLogoUrls(
  publishingEntity: PublishingEntity
): readonly string[] {
  const primary = publishingEntity.assets.find(
    (x) => x.type === PublishingEntityAssetType.PRIMARY
  );
  const secondary = publishingEntity.assets.find(
    (x) => x.type === PublishingEntityAssetType.SECONDARY
  );
  const logos = compact([primary, secondary]);
  const urls = logos.map((x) => x.url);
  return urls;
}

/**
 * returns an array of choice Ids
 */
export function generateUniformChoiceIdsArray(
  vote: VotingProps.Vote | null
): readonly string[] | null {
  if (!vote) return null;
  switch (vote.type) {
    case QuestionType.FREE_TEXT:
      return null;
    case QuestionType.MULTIPLE_CHOICE:
      return vote.choices.map((ch) => ch.id);
    case QuestionType.POINT_ALLOCATION:
      return vote.choices.map((ch) => ch.id);
    default:
      return null;
  }
}

export const previousVoteToChoiceText = (
  previousVote: VotingProps.Vote_MultipleChoice | VotingProps.Vote_PointAllocation,
  choices: readonly VotingChoice[]
): readonly string[] => {
  // This is required because we can't map over different types, even though they both have ids
  switch (previousVote.type) {
    case QuestionType.MULTIPLE_CHOICE: {
      return compact(
        previousVote.choices.map((ch) => {
          const choice = choices.find((c) => c.choice.id === ch.id);
          return choice?.choice.text;
        })
      );
    }
    case QuestionType.POINT_ALLOCATION: {
      return compact(
        previousVote.choices.map((ch) => {
          const choice = choices.find((c) => c.choice.id === ch.id);
          return choice?.choice.text;
        })
      );
    }
  }
};

export function voteIncludesChoice(
  type: QuestionType.MULTIPLE_CHOICE | QuestionType.POINT_ALLOCATION,
  vote: VotingProps.Vote_MultipleChoice | VotingProps.Vote_PointAllocation | null,
  choices: readonly VotingChoice[],
  choiceId: QuestionChoice['id']
): boolean {
  if (!vote) return false;

  if (type === QuestionType.MULTIPLE_CHOICE) {
    return vote.choices.map((ch) => ch.id).includes(choiceId);
  } else {
    return choices.some((ch) => ch.choice.id === choiceId && !!ch.votesCount);
  }
}

export function hasVote(vote: VotingProps.Vote | null): boolean {
  if (!vote) {
    return false;
  }
  switch (vote.type) {
    case QuestionType.MULTIPLE_CHOICE:
    case QuestionType.POINT_ALLOCATION:
      return vote.choices.length > 0;
    case QuestionType.GRID_CHOICE:
      return Object.keys(vote.gridChoiceByRowId).length > 0;
    case QuestionType.FREE_TEXT:
      return (vote.comment?.comment.length ?? 0) > 0;
  }
}

/* taken from - https://stackoverflow.com/questions/12043187/how-to-check-if-hex-color-is-too-black
checks a hex color to see if it is dark enough to use white text */
export function isColorDark(hex: string): boolean {
  const c = hex.substring(1); // strip #
  const rgb = parseInt(c, 16); // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff; // extract red
  const g = (rgb >> 8) & 0xff; // extract green
  const b = (rgb >> 0) & 0xff; // extract blue

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

  return luma < 110;
}

const copy = {
  go: 'Go!',
  share: 'Share',
  bookmark: 'Bookmark',
  unbookmark: 'Remove Bookmark',
  following: 'Following',
  unfollow: 'Unfollow',
  follow: 'Follow',
  shareVia: 'Share via...',
  openPublisher: 'View Profile',
  openSet: 'Vote',
};

export interface FeedConverterArgs {
  readonly followAction: MutationReturn<
    FollowPublishingEntity,
    FollowPublishingEntityVariables
  >;
  readonly voteAction: MutationReturn<VoteForQuestion, VoteForQuestionVariables>;
  readonly respId: ClientRespondentId | null;
  readonly isGuest?: boolean;
  readonly setPublisherAlert: React.Dispatch<
    React.SetStateAction<PublisherAlert | null>
  >;
  readonly setShowLoginPrompt: React.Dispatch<React.SetStateAction<boolean>>;
  readonly currentLocation: UserLocationWithSource | null;
  readonly dispatch: React.Dispatch<RespondentActions.Types>;
  readonly selectLanguageText: SelectLanguageTextFunction;
  readonly setSharingUrl: React.Dispatch<React.SetStateAction<string | null>>;
  readonly ideaVoteAction: MutationReturn<
    InteractWithIdea,
    InteractWithIdeaVariables
  > | null;
}

interface FeedActionItemConverterArgs extends FeedConverterArgs {
  readonly item: RespondentFeed_Item;
}

const balancingActTypeName = 'RespondentFeedItemTypedData_BalancingActSimulation';
const questionTypeName = 'RespondentFeedItemTypedData_Question';
const questionSetTypeName = 'RespondentFeedItemTypedData_QuestionSet';
const ideaTypeName = 'RespondentFeedItemTypedData_Idea';

interface ItemConverterResult {
  readonly postTitle: string;
  readonly authorLogo: string;
  readonly authorName: string;
  readonly authorSubheader?: string;
  readonly authorAction?: LinkAction;
  readonly pills?: readonly string[];
  readonly secondaryLogo?: string;
  readonly postImage?: string;
  readonly contentClosed?: boolean;
  readonly singleQuestion?: boolean;
  readonly dotActions?: readonly OptionListMenuOptionProps[];
}

export function getItemConvertedData(
  args: FeedConverterArgs,
  item: RespondentFeed_Item,
  mapActions: (a: {
    readonly __typename: 'RespondentFeedItemAction';
    readonly type: RespondentFeedItemActionType;
  }) => BannerAction
): ItemConverterResult {
  const typedData = item.typedData;
  switch (typedData.__typename) {
    case ideaTypeName:
      const title = args.selectLanguageText(item.title);
      return {
        postTitle: shortenIdeaTitle(title),
        authorLogo: ideaLogo,
        postImage: ideaBanner,
        authorName: typedData.idea.respondent.publicName,
        authorSubheader: timeAgo(typedData.idea.createDate),
        pills: [args.selectLanguageText(item.publishingEntity.name)],
        dotActions: buildDotOption(args, item, mapActions),
      };
    case balancingActTypeName:
      return {
        postTitle: args.selectLanguageText(item.title),
        authorLogo:
          publishingEntityPrimaryLogoUrl(item.publishingEntity.assets) ?? logo,
        postImage: balancingActBanner,
        authorName: args.selectLanguageText(item.publishingEntity.name),
        authorSubheader: 'with Balancing Act',
        authorAction: {
          type: 'LINK',
          url: ClientUrlUtils.respondent.pubProfile.path({
            slug: item.publishingEntity.slug,
          }),
        },
        secondaryLogo: balancingActLogo,
        contentClosed: typedData.contentState === RespondentFeedItemStatus.CLOSED,
      };
    case questionSetTypeName:
    case questionTypeName:
      return {
        postTitle: args.selectLanguageText(item.title),
        authorLogo:
          publishingEntityPrimaryLogoUrl(item.publishingEntity.assets) ?? logo,
        postImage: item.image ?? undefined,
        authorName: args.selectLanguageText(item.publishingEntity.name),
        authorSubheader: item.publishingEntity.presidingArea?.name ?? undefined,
        authorAction: {
          type: 'LINK',
          url: ClientUrlUtils.respondent.pubProfile.path({
            slug: item.publishingEntity.slug,
          }),
        },
        singleQuestion:
          typedData.__typename === 'RespondentFeedItemTypedData_Question'
            ? typedData.acknowledgeOnly
            : undefined,
        contentClosed: typedData.contentState === RespondentFeedItemStatus.CLOSED,
        dotActions: buildDotOption(args, item, mapActions),
      };
  }
}

function buildDotOption(
  args: FeedConverterArgs,
  item: RespondentFeed_Item,
  mapActions: (a: {
    readonly __typename: 'RespondentFeedItemAction';
    readonly type: RespondentFeedItemActionType;
  }) => BannerAction
): readonly OptionListMenuOptionProps[] {
  const typedData = item.typedData;

  switch (typedData.__typename) {
    case ideaTypeName:
      const title = args.selectLanguageText(item.title);

      return [
        {
          label: IDEA_COPY.actions.share,
          icon: mapActionToIcon(IdeaActionType.SHARE),
          action: () => args.setSharingUrl(shareIdeaUrl(typedData.idea.id)),
        },
        {
          label: IDEA_COPY.actions.report.label,
          description: IDEA_COPY.actions.report.description.intro,
          icon: mapActionToIcon(IdeaActionType.REPORT),
          action: () => reportIdea({ id: typedData.idea.id, title }),
        },
      ];
    case questionSetTypeName:
    case questionTypeName:
      const topAction = item.topAction ? mapActions(item.topAction) : undefined;
      const showTopActionAsDot =
        item.topAction?.type === RespondentFeedItemActionType.TOGGLE_FOLLOW &&
        topAction?.action.type === 'ACTION';
      const isFollowingPublisher = isPublisherFollowed(
        item.publishingEntity.subscriptionType
      );
      const isShareable =
        typedData.__typename === 'RespondentFeedItemTypedData_QuestionSet' &&
        typedData.contentType === QuestionSetType.SURVEY
          ? typedData.shareable
          : true;

      if (!showTopActionAsDot) {
        return [];
      }

      return compact([
        {
          label: `${
            isFollowingPublisher ? copy.unfollow : copy.follow
          } ${args.selectLanguageText(item.publishingEntity.name)}`,
          icon: isFollowingPublisher
            ? MaterialIconName.PERSON_REMOVE_ALT_1
            : MaterialIconName.PERSON_ADD_ALT_1,
          action: topAction.action.action,
        },
        isShareable
          ? {
              label: copy.shareVia,
              icon: MaterialIconName.SHARE,
              action: () =>
                args.setSharingUrl(
                  ClientUrlUtils.common.pathToUrl(
                    generatePrimaryActionUrl(item, generateIdOrSlug(item))
                  )
                ),
            }
          : null,
      ]);
    case balancingActTypeName:
      return [];
  }
}

export function feedItemConverter(
  args: FeedConverterArgs
): (item: RespondentFeed_Item) => FeedItem {
  return (item) => {
    const mapActions = gqlFeedActionToClientConverter({ ...args, item });
    const {
      postTitle,
      authorLogo,
      authorName,
      authorSubheader,
      postImage,
      authorAction,
      contentClosed,
      pills,
      secondaryLogo,
      dotActions,
      singleQuestion = false,
    } = getItemConvertedData(args, item, mapActions);
    const voteButton = gqlFeedItemTypeToVoteButton({ ...args, item });

    return {
      key: item.id,
      header: {
        authorName,
        authorLogo,
        secondaryLogo,
        authorSubheader,
        authorAction,
        dotActions,
        topAction: item.topAction ? mapActions(item.topAction) : undefined,
      },
      banner: {
        key: item.id,
        singleQuestion,
        contentClosed,
        postImage,
        postTitle,
        postBottomText: item.bottomData ?? ' ',
        postBottomIcon: item.bottomIcon ?? undefined,
        badges: item.badges.map((b) => ({
          label: b.label,
          type: convertBadgeType_GqlToClient(b.type),
        })),
        primaryAction: mapActions(item.primaryAction),
        bottomActions: item.secondaryBottomActions.map(mapActions),
        pills,
        typedData: item.typedData,
        voteButton,
      },
    };
  };
}

function generatePrimaryActionUrl(item: RespondentFeed_Item, idOrSlug: string) {
  switch (item.typedData.__typename) {
    case questionTypeName:
      return ClientUrlUtils.respondent.question.path({
        questionId: item.typedData.id,
        setIdOrSlug: idOrSlug,
        pubSlug: item.publishingEntity.slug,
        setType: QuestionSetType.SET,
      });
    case questionSetTypeName:
      return ClientUrlUtils.respondent.set.path({
        setIdOrSlug: idOrSlug,
        pubSlug: item.publishingEntity.slug,
        setType: item.typedData.contentType,
      });
    case balancingActTypeName:
      return ClientUrlUtils.respondent.balancingActSimulation.path({
        simulationId: idOrSlug,
        isTaxReceipt: item.typedData.simulationType === 'receipt',
      });
    case ideaTypeName:
      return ClientUrlUtils.respondent.viewIdea.path(item.typedData.idea.id);
  }
}

function generateIdOrSlug(item: RespondentFeed_Item) {
  switch (item.typedData.__typename) {
    case questionTypeName:
      return item.typedData.setSlug ?? item.typedData.setId;
    case questionSetTypeName:
      return item.typedData.slug ?? item.typedData.id;
    case balancingActTypeName:
      return item.typedData.id;
    case ideaTypeName:
      return item.typedData.idea.id;
  }
}

export function gqlFeedActionToClientConverter(
  args: FeedActionItemConverterArgs
): (a: RespondentFeed_Item['secondaryBottomActions'][0]) => BannerAction {
  const {
    item,
    followAction,
    respId,
    isGuest,
    setPublisherAlert,
    setShowLoginPrompt,
    voteAction,
    currentLocation,
    dispatch,
  } = args;

  return (a) => {
    const followingPub = isPublisherFollowed(item.publishingEntity.subscriptionType);

    const idOrSlug = wrap(() => generateIdOrSlug(item));

    switch (a.type) {
      case RespondentFeedItemActionType.OPEN_PUBLISHER:
        return {
          key: a.type,
          content: { type: 'LABEL', label: copy.go },
          action: {
            type: 'LINK',
            url: ClientUrlUtils.respondent.pubProfile.path({
              slug: item.publishingEntity.slug,
            }),
          },
        };
      case RespondentFeedItemActionType.OPEN_OUTCOME:
        return {
          key: a.type,
          content: { type: 'LABEL', label: item.primaryCallout },
          action: {
            type: 'LINK',
            url: ClientUrlUtils.respondent.setOutcome.path({
              setIdOrSlug: idOrSlug,
              pubSlug: item.publishingEntity.slug,
            }),
          },
        };
      case RespondentFeedItemActionType.OPEN_ITEM:
        return {
          key: a.type,
          content: { type: 'LABEL', label: item.primaryCallout },
          action: {
            type: 'LINK',
            url: wrap(() => generatePrimaryActionUrl(item, idOrSlug)),
          },
        };
      case RespondentFeedItemActionType.SHARE:
        return {
          key: a.type,
          content: { type: 'LABEL', label: copy.share },
          action: {
            type: 'ACTION',
            action: () => {
              if (process.env.NODE_ENV !== 'test') {
                console.error('share');
              }
            },
          },
        };
      case RespondentFeedItemActionType.TOGGLE_FOLLOW: {
        return {
          key: a.type,
          content: {
            type: 'LABEL',
            label: followingPub ? copy.following : copy.follow,
            active: followingPub,
          },
          action: {
            type: 'ACTION',
            action: async () => {
              if (respId && !isGuest) {
                if (followingPub) {
                  setPublisherAlert({
                    publisher: item.publishingEntity,
                    action: 'UNFOLLOW',
                  });
                  return;
                } else {
                  await followAction.fn({
                    variables: {
                      publishingEntityId: item.publishingEntity.id,
                      follow: !followingPub,
                      respondentId: respId,
                    },
                    refetchQueries: [
                      QueryInfos.respondentSubscriptions.refetchInfo({
                        respondentId: respId,
                      }),
                    ],
                  });
                }
              } else {
                setPublisherAlert({
                  publisher: item.publishingEntity,
                  action: 'FOLLOW',
                });

                dispatch(
                  RespondentActions.promptRegistration({
                    actionType: NotLoggedInActions.FOLLOW,
                    redirectLink: ClientUrlUtils.respondent.feed.path(),
                    data: {
                      pubId: item.publishingEntity.id,
                      publisher: item.publishingEntity,
                    },
                    status: NotLoggedInActionStatus.PRE_REGISTRATION,
                    registrationType: null,
                  })
                );
                setShowLoginPrompt(true);
              }
            },
          },
        };
      }
      case RespondentFeedItemActionType.VOTE: {
        return {
          key: a.type,
          content: {
            type: 'LABEL',
            label: item.primaryCallout,
          },
          disabled:
            item.typedData.__typename === questionTypeName
              ? item.typedData.voted
              : true,
          action: {
            type: 'ACTION',
            action: async () => {
              if (item.typedData.__typename !== questionTypeName) {
                return;
              }
              if (respId) {
                await voteAction.fn({
                  variables: {
                    questionId: item.typedData.id,
                    upvoteComments: [],
                    voteInput: {
                      //TODO eventually handle more types here when we can vote on all question types in the feed
                      type: InputVoteType.VOTE_ACKNOWLEDGE,
                    },
                    voteMetadata: [],
                  },
                  refetchQueries: [
                    QueryInfos.respondentFeed.refetchInfo({
                      respondentId: respId,
                      zipCode: currentLocation?.zip,
                      latLng: currentLocation?.latLng,
                    }),
                  ],
                });
              } else {
                dispatch(
                  RespondentActions.promptRegistration({
                    actionType: NotLoggedInActions.QUESTION_VOTE,
                    redirectLink: ClientUrlUtils.respondent.feed.path(),
                    data: {
                      questionId: item.typedData.id,
                      publisher: item.publishingEntity,
                    },
                    status: NotLoggedInActionStatus.PRE_REGISTRATION,
                    registrationType: null,
                  })
                );
                setShowLoginPrompt(true);
              }
            },
          },
        };
      }
    }
  };
}

export function gqlFeedItemTypeToVoteButton(
  args: FeedActionItemConverterArgs
): AuthnVoteSectionProps | undefined {
  const typedData = args.item.typedData;
  switch (typedData.__typename) {
    case ideaTypeName:
      const config = getConfig();
      const votingEnabled = config.envFeatures.enableIdeaVoting;
      if (!votingEnabled) {
        return;
      }
      const { respId, ideaVoteAction } = args;
      const idea = typedData.idea;
      const action = async (voteType: VoteType) => {
        if (!ideaVoteAction || !respId) {
          return;
        }

        await ideaVoteAction.fn({
          variables: {
            input: {
              ideaId: idea.id,
              action: voteTypeToIdeaInteractionAction(voteType),
            },
            respondentId: respId,
          },
        });
      };

      const voteCounts = {
        up: idea.aggregations?.voteUp ?? 0,
        down: idea.aggregations?.voteDown ?? 0,
      };

      return {
        action,
        respondentVote: idea.respondentVote,
        voteCounts,
        respId: respId || undefined,
        promptItems: {
          image: AUTHN_CONTEXT.ideaVote.image,
          description: AUTHN_CONTEXT.ideaVote.description,
          title: AUTHN_CONTEXT.ideaVote.title,
          secondaryButtonLabel: AUTHN_CONTEXT.ideaVote.secondaryButtonLabel,
        },
      };
    default:
      return;
  }
}

export function isPublisherFollowed(subscriptionType: SubscriptionType | null) {
  return (
    !!subscriptionType &&
    [SubscriptionType.MANUAL, SubscriptionType.AUTOMATIC].includes(subscriptionType)
  );
}

import * as React from 'react';
import { VotingProps } from 'client/respondent/core/types';
import { FreeTextVote } from 'client/shared/components/free-text-vote';
import { MCVote } from 'client/shared/components/mc-vote';
import { PAVote } from 'client/shared/components/pa-vote';
import {
  VotingChoice,
  QuestionType,
  ClientQuestionId,
} from 'client/shared/core/question';
import './styles.scss';
import { LoadedEvents } from 'client/shared/core/types';

export interface VotingButtonsProps {
  readonly typedData: VotingProps.QuestionTypedData;
  readonly choices: readonly VotingChoice[];
  readonly inProcessComment: VotingProps.InProcessComment | null;
  readonly events: {
    readonly selectMultipleChoice: LoadedEvents['selectMultipleChoice'];
    readonly selectPointAllocation: LoadedEvents['selectPointAllocation'];
    readonly startInProcessVote: LoadedEvents['startInProcessVote'];
    readonly updateCommentText: LoadedEvents['updateCommentText'];
    readonly submit: LoadedEvents['submitVote'];
    readonly cancel: () => void;
  };
  readonly questionId: ClientQuestionId;
  readonly submitting: boolean;
  readonly allowGuestRespondents: boolean;
  readonly title: string;
}

export const VotingButtons: React.FC<VotingButtonsProps> = (props) => {
  React.useEffect(() => {
    if (props.typedData.inProcessVote?.questionId !== props.questionId) {
      props.events.startInProcessVote(
        props.questionId,
        props.typedData.questionType
      );
    }
    if (
      props.inProcessComment &&
      props.inProcessComment.questionId !== props.questionId
    ) {
      props.events.updateCommentText('', props.questionId);
    }
  }, [
    props.events,
    props.inProcessComment,
    props.questionId,
    props.typedData.inProcessVote,
    props.typedData.questionType,
  ]);

  switch (props.typedData.questionType) {
    case QuestionType.FREE_TEXT:
      return (
        <FreeTextVote
          ariaLabel={props.title}
          inProcessComment={props.inProcessComment?.comment ?? ''}
          updateCommentText={(comment: string) =>
            props.events.updateCommentText(comment, props.questionId)
          }
        />
      );
    case QuestionType.MULTIPLE_CHOICE:
      return (
        <MCVote
          choices={props.choices.map((ch) => ({
            id: ch.choice.id,
            label: ch.choice.text,
          }))}
          commentByChoice={{}} // TODO update when commentable choices are supported in poll sets
          maxSelection={props.typedData.maxSelection}
          questionId={props.questionId}
          questionTitle={props.title}
          selectMultipleChoice={
            props.submitting
              ? { type: 'SUBMITTING' }
              : {
                  type: 'ACTION',
                  action: props.events.selectMultipleChoice,
                }
          }
          selectedChoices={props.typedData.selectedChoices}
          updateComment={() => {}} // TODO update when commentable choices are supported in poll sets
        />
      );
    case QuestionType.POINT_ALLOCATION:
      return (
        <PAVote
          choices={props.choices.map((ch) => ({
            id: ch.choice.id,
            label: ch.choice.text,
          }))}
          questionId={props.questionId}
          selectPointAllocation={props.events.selectPointAllocation}
          selectedChoices={props.typedData.selectedChoices}
        />
      );
    default:
      return null;
  }
};

import {
  VotingProps,
  PublishingEntity,
  UserLocation,
  LocationSource,
  ConversionType,
  PreLoginAction,
  CurrentQuestion,
  QuestionMetadataEntry,
} from 'client/respondent/core/types';
import {
  ClientQuestionId,
  QuestionType,
  SavedSurveyItem,
  RandomizedSurveyItems,
  ClientQuestionChoiceId,
} from 'client/shared/core/question';
import { ClientQuestionSetId } from 'client/shared/core/question-set';
import {
  MCChoice,
  PointAllocationChoice,
  AnswerChangeability,
  VotingInteraction,
  GridChoice,
} from 'client/shared/core/types';

export enum ActionTypeKeys {
  SET_REDIRECT = 'SET_REDIRECT',
  VOTING_START_IN_PROCESS_VOTE = 'VOTING_START_IN_PROCESS_VOTE',
  VOTING_SELECTED_MULTIPLE_CHOICE = 'VOTING_SELECTED_MULTIPLE_CHOICE',
  VOTING_SELECTED_POINT_ALLOCATION = 'VOTING_SELECTED_POINT_ALLOCATION',
  VOTING_CLEAR_IN_PROCESS_VOTE = 'VOTING_CLEAR_IN_PROCESS_VOTE',
  VOTING_UPDATE_COMMENT_TEXT = 'VOTING_UPDATE_COMMENT_TEXT',
  VOTING_UPDATE_CHOICE_TEXT = 'VOTING_UPDATE_CHOICE_TEXT',
  VOTING_CHANGE_ANSWER = 'VOTING_CHANGE_ANSWER',
  VOTING_CANCEL_INTERACTION = 'VOTING_CANCEL_INTERACTION',
  VOTING_COMPLETE_INTERACTION = 'VOTING_COMPLETE_INTERACTION',
  PROMPT_REGISTRATION = 'PROMPT_REGISTRATION',
  REGISTRATION_SUCCESSFUL = 'REGISTRATION_SUCCESSFUL',
  // survey specific actions
  VOTING_SURVEY_START_IN_PROCESS_VOTE = 'VOTING_SURVEY_START_IN_PROCESS_VOTE',
  VOTING_SURVEY_SELECTED_MULTIPLE_CHOICE = 'VOTING_SURVEY_SELECTED_MULTIPLE_CHOICE',
  VOTING_SURVEY_SELECTED_POINT_ALLOCATION = 'VOTING_SURVEY_SELECTED_POINT_ALLOCATION',
  VOTING_SURVEY_FREE_TEXT_UPDATE_TEXT = 'VOTING_SURVEY_FREE_TEXT_UPDATE_TEXT',
  VOTING_SURVEY_SELECTED_GRID_CHOICE = 'VOTING_SURVEY_SELECTED_GRID_CHOICE',
  VOTING_SURVEY_TOTAL_ANSWERED_QUESTION = 'VOTING_SURVEY_TOTAL_ANSWERED_QUESTION',
  SURVEY_SUBMIT_CLEAR_VOTES = 'SURVEY_SUBMIT_CLEAR_VOTES',
  SET_RANDOMIZED_SURVEY_ITEMS = 'SET_RANDOMIZED_SURVEY_ITEMS',
  CLEAR_RANDOMIZED_SURVEY_ITEMS = 'CLEAR_RANDOMIZED_SURVEY_ITEMS',

  SET_SHOW_FULL_DETAILS = 'SET_SHOW_FULL_DETAILS',
  PREVOTE_STORE_COMMENT_UPVOTE = 'PREVOTE_STORE_COMMENT_UPVOTE',
  PREVOTE_REMOVE_COMMENT_UPVOTE = 'PREVOTE_REMOVE_COMMENT_UPVOTE',
  SAW_PUBLISHING_ENTITY_BANNER = 'SAW_PUBLISHING_ENTITY_BANNER',
  VOTING_QUESTION_CHANGED = 'VOTING_QUESTION_CHANGED',
  VOTING_SET_INTERACTION = 'VOTING_SET_INTERACTION',
  SET_LAST_FEED_LOCATION = 'SET_LAST_FEED_LOCATION',
  COMPLETE_PRE_LOGIN_ACTION = 'COMPLETE_PRE_LOGIN_ACTION',
  PRE_LOGIN_ACTION_FAILURE = 'PRE_LOGIN_ACTION_FAILURE',
  SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION',
  SET_QUESTION_METADATA = 'SET_QUESTION_METADATA',
  SET_QUESTION_METADATA_ITEM = 'SET_QUESTION_METADATA_ITEM',
  CLEAR_QUESTION_METADATA_ITEM = 'CLEAR_QUESTION_METADATA_ITEM',
  SET_MOST_RECENT_CONTENT_SET_THAT_PROMPTED_CONVERSION = 'SET_MOST_RECENT_CONTENT_SET_THAT_PROMPTED_CONVERSION',
  OTHER_ACTION = '__fake_to_support_system_events__',
}

export namespace RespondentActions {
  export type Types =
    | StartInProcessVote
    | SelectedMultipleChoice
    | SelectedPointAllocation
    | UpdateCommentText
    | UpdateChoiceText
    | ChangeAnswer
    | ClearInProcessVote
    | CancelInteraction
    | CompleteInteraction
    | RegistrationSuccessful
    | PromptRegistration
    | QuestionChanged
    | SetShowFullDetails
    | PrevoteStoreCommentUpvote
    | PrevoteRemoveCommentUpvote
    | SawPublishingEntityBanner
    | SetInteraction
    | StartSurveyInProcessVote
    | SurveySelectedMultipleChoice
    | SurveySelectedPointAllocation
    | SurveyUpdateFreeTextComment
    | SurveySelectedGridChoice
    | SurveySubmitClearVote
    | SetLastFeedLocation
    | CompletePreLoginAction
    | PreLoginActionFailure
    | SetCurrentLocation
    | SetQuestionMetadata
    | SetQuestionMetadataItem
    | ClearQuestionMetadataItem
    | SetRandomizedSurveyItems
    | ClearRandomizedSurveyItems
    | ReturnType<typeof setMostRecentContentSetThatPromptedConversion>;

  export interface StartInProcessVote {
    readonly type: ActionTypeKeys.VOTING_START_IN_PROCESS_VOTE;
    readonly questionId: ClientQuestionId;
    readonly questionType: QuestionType;
  }

  export const startInProcessVote = (
    questionId: ClientQuestionId,
    questionType: QuestionType
  ): StartInProcessVote => ({
    type: ActionTypeKeys.VOTING_START_IN_PROCESS_VOTE,
    questionId,
    questionType,
  });

  export interface SelectedMultipleChoice {
    readonly type: ActionTypeKeys.VOTING_SELECTED_MULTIPLE_CHOICE;
    readonly choices: readonly MCChoice[];
    readonly questionId: ClientQuestionId;
  }

  export const selectedMultipleChoice = (
    choices: readonly MCChoice[],
    questionId: ClientQuestionId
  ): SelectedMultipleChoice => ({
    type: ActionTypeKeys.VOTING_SELECTED_MULTIPLE_CHOICE,
    choices,
    questionId,
  });

  export interface SelectedPointAllocation {
    readonly type: ActionTypeKeys.VOTING_SELECTED_POINT_ALLOCATION;
    readonly choices: readonly PointAllocationChoice[];
    readonly questionId: ClientQuestionId;
  }

  export const selectedPointAllocation = (
    choices: readonly PointAllocationChoice[],
    questionId: ClientQuestionId
  ): SelectedPointAllocation => ({
    type: ActionTypeKeys.VOTING_SELECTED_POINT_ALLOCATION,
    choices,
    questionId,
  });
  //when mount a voting component, send in inProcessVote with types

  export interface ClearInProcessVote {
    readonly type: ActionTypeKeys.VOTING_CLEAR_IN_PROCESS_VOTE;
  }

  export const clearInProcessVote = (): ClearInProcessVote => ({
    type: ActionTypeKeys.VOTING_CLEAR_IN_PROCESS_VOTE,
  });

  export interface UpdateCommentText {
    readonly type: ActionTypeKeys.VOTING_UPDATE_COMMENT_TEXT;
    readonly comment: string;
    readonly questionId: ClientQuestionId;
  }

  export const updateCommentText = (
    comment: string,
    questionId: ClientQuestionId
  ): UpdateCommentText => ({
    type: ActionTypeKeys.VOTING_UPDATE_COMMENT_TEXT,
    comment,
    questionId,
  });

  export interface UpdateChoiceText {
    readonly type: ActionTypeKeys.VOTING_UPDATE_CHOICE_TEXT;
    readonly comment: string;
    readonly questionId: ClientQuestionId;
    readonly questionChoiceId: ClientQuestionChoiceId;
  }

  export const updateChoiceText = (
    comment: string,
    questionId: ClientQuestionId,
    questionChoiceId: ClientQuestionChoiceId
  ): UpdateChoiceText => ({
    type: ActionTypeKeys.VOTING_UPDATE_CHOICE_TEXT,
    comment,
    questionId,
    questionChoiceId,
  });

  export interface ChangeAnswer {
    readonly type: ActionTypeKeys.VOTING_CHANGE_ANSWER;
    readonly currentVote: VotingProps.Vote | null;
    readonly which: AnswerChangeability;
  }

  export const changeAnswer = (a: {
    readonly currentVote: VotingProps.Vote | null;
    readonly which: AnswerChangeability;
  }): ChangeAnswer => ({
    ...a,
    type: ActionTypeKeys.VOTING_CHANGE_ANSWER,
  });

  export interface CancelInteraction {
    readonly type: ActionTypeKeys.VOTING_CANCEL_INTERACTION;
  }

  export const cancelInteraction = (): CancelInteraction => ({
    type: ActionTypeKeys.VOTING_CANCEL_INTERACTION,
  });

  export interface CompleteInteraction {
    readonly type: ActionTypeKeys.VOTING_COMPLETE_INTERACTION;
  }

  export const completeInteraction = (): CompleteInteraction => ({
    type: ActionTypeKeys.VOTING_COMPLETE_INTERACTION,
  });

  export interface RegistrationSuccessful {
    readonly type: ActionTypeKeys.REGISTRATION_SUCCESSFUL;
    readonly conversionType: ConversionType;
  }

  export const registrationSuccessful = (
    conversionType: ConversionType
  ): RegistrationSuccessful => ({
    type: ActionTypeKeys.REGISTRATION_SUCCESSFUL,
    conversionType,
  });

  export interface PromptRegistration {
    readonly type: ActionTypeKeys.PROMPT_REGISTRATION;
    readonly preLoginAction: PreLoginAction | null;
  }

  export const promptRegistration = (
    preLoginAction: PreLoginAction | null
  ): PromptRegistration => ({
    type: ActionTypeKeys.PROMPT_REGISTRATION,
    preLoginAction,
  });

  export interface QuestionChanged {
    readonly type: ActionTypeKeys.VOTING_QUESTION_CHANGED;
    readonly newQuestion: CurrentQuestion | null;
  }

  export const questionChanged = (newQuestion: CurrentQuestion | null) => ({
    type: ActionTypeKeys.VOTING_QUESTION_CHANGED,
    newQuestion,
  });

  export interface SetShowFullDetails {
    readonly type: ActionTypeKeys.SET_SHOW_FULL_DETAILS;
    readonly showFullDetails: boolean;
  }
  export const setShowFulLDetails = (
    showFullDetails: boolean
  ): SetShowFullDetails => ({
    type: ActionTypeKeys.SET_SHOW_FULL_DETAILS,
    showFullDetails,
  });

  export interface PrevoteStoreCommentUpvote {
    readonly type: ActionTypeKeys.PREVOTE_STORE_COMMENT_UPVOTE;
    readonly commentId: VotingProps.Comment['id'];
  }
  export const prevoteStoreCommentUpvote = (
    commentId: VotingProps.Comment['id']
  ): PrevoteStoreCommentUpvote => ({
    type: ActionTypeKeys.PREVOTE_STORE_COMMENT_UPVOTE,
    commentId,
  });

  export interface PrevoteRemoveCommentUpvote {
    readonly type: ActionTypeKeys.PREVOTE_REMOVE_COMMENT_UPVOTE;
    readonly commentId: VotingProps.Comment['id'];
  }
  export const prevoteRemoveCommentUpvote = (
    commentId: VotingProps.Comment['id']
  ): PrevoteRemoveCommentUpvote => ({
    type: ActionTypeKeys.PREVOTE_REMOVE_COMMENT_UPVOTE,
    commentId,
  });

  export interface SawPublishingEntityBanner {
    readonly type: ActionTypeKeys.SAW_PUBLISHING_ENTITY_BANNER;
    readonly publishingEntityId: PublishingEntity['id'];
  }
  export const sawPublishingEntityBanner = (
    publishingEntityId: PublishingEntity['id']
  ) => ({
    type: ActionTypeKeys.SAW_PUBLISHING_ENTITY_BANNER,
    publishingEntityId,
  });

  export interface SetInteraction {
    readonly type: ActionTypeKeys.VOTING_SET_INTERACTION;
    readonly interaction: VotingInteraction;
  }

  export const setInteraction = (
    interaction: VotingInteraction
  ): SetInteraction => ({
    type: ActionTypeKeys.VOTING_SET_INTERACTION,
    interaction,
  });

  // survey specific actions and interfaces
  export interface StartSurveyInProcessVote {
    readonly type: ActionTypeKeys.VOTING_SURVEY_START_IN_PROCESS_VOTE;
    readonly surveyItems: readonly SavedSurveyItem[];
    readonly setId: ClientQuestionSetId;
  }

  export const startSurveyInProcessVote = (
    setId: ClientQuestionSetId,
    surveyItems: readonly SavedSurveyItem[]
  ): StartSurveyInProcessVote => ({
    type: ActionTypeKeys.VOTING_SURVEY_START_IN_PROCESS_VOTE,
    surveyItems,
    setId,
  });

  export interface SetRandomizedSurveyItems {
    readonly type: ActionTypeKeys.SET_RANDOMIZED_SURVEY_ITEMS;
    readonly randomizedSurveyItems: RandomizedSurveyItems;
  }

  export const setRandomizedSurveyItems = (
    randomizedSurveyItems: RandomizedSurveyItems
  ): SetRandomizedSurveyItems => ({
    type: ActionTypeKeys.SET_RANDOMIZED_SURVEY_ITEMS,
    randomizedSurveyItems,
  });

  export interface ClearRandomizedSurveyItems {
    readonly type: ActionTypeKeys.CLEAR_RANDOMIZED_SURVEY_ITEMS;
    readonly choicesIds: readonly string[];
  }

  export const clearRandomizedSurveyItems = (
    choicesIds: readonly string[]
  ): ClearRandomizedSurveyItems => ({
    type: ActionTypeKeys.CLEAR_RANDOMIZED_SURVEY_ITEMS,
    choicesIds,
  });

  export interface SurveySubmitClearVote {
    readonly type: ActionTypeKeys.SURVEY_SUBMIT_CLEAR_VOTES;
    readonly setId: ClientQuestionSetId;
  }

  export const surveySubmitClearVote = (
    setId: ClientQuestionSetId
  ): SurveySubmitClearVote => ({
    type: ActionTypeKeys.SURVEY_SUBMIT_CLEAR_VOTES,
    setId,
  });
  export interface SurveySelectedMultipleChoice {
    readonly type: ActionTypeKeys.VOTING_SURVEY_SELECTED_MULTIPLE_CHOICE;
    readonly choices: readonly MCChoice[];
    readonly questionId: ClientQuestionId;
  }
  export const surveySelectedMultipleChoice = (
    choices: readonly MCChoice[],
    questionId: ClientQuestionId
  ): SurveySelectedMultipleChoice => ({
    type: ActionTypeKeys.VOTING_SURVEY_SELECTED_MULTIPLE_CHOICE,
    choices,
    questionId,
  });

  export interface SurveySelectedPointAllocation {
    readonly type: ActionTypeKeys.VOTING_SURVEY_SELECTED_POINT_ALLOCATION;
    readonly choices: readonly PointAllocationChoice[];
    readonly questionId: ClientQuestionId;
  }

  export const surveySelectedPointAllocation = (
    choices: readonly PointAllocationChoice[],
    questionId: ClientQuestionId
  ): SurveySelectedPointAllocation => ({
    type: ActionTypeKeys.VOTING_SURVEY_SELECTED_POINT_ALLOCATION,
    choices,
    questionId,
  });

  export interface SurveyUpdateFreeTextComment {
    readonly questionId: ClientQuestionId;
    readonly type: ActionTypeKeys.VOTING_SURVEY_FREE_TEXT_UPDATE_TEXT;
    readonly comment: string;
  }
  export const surveyUpdateFreeTextComment = (
    questionId: ClientQuestionId,
    comment: string
  ): SurveyUpdateFreeTextComment => ({
    type: ActionTypeKeys.VOTING_SURVEY_FREE_TEXT_UPDATE_TEXT,
    questionId,
    comment,
  });

  export interface SurveySelectedGridChoice {
    readonly questionId: ClientQuestionId;
    readonly type: ActionTypeKeys.VOTING_SURVEY_SELECTED_GRID_CHOICE;
    readonly gridChoice: GridChoice;
    readonly numCol: number;
  }

  export const surveySelectedGridChoice = (
    questionId: ClientQuestionId,
    gridChoice: GridChoice,
    numCol: number
  ): SurveySelectedGridChoice => ({
    questionId,
    gridChoice,
    numCol,
    type: ActionTypeKeys.VOTING_SURVEY_SELECTED_GRID_CHOICE,
  });

  export interface SetLastFeedLocation {
    readonly pubIdOrSlug: string | null;
    readonly type: ActionTypeKeys.SET_LAST_FEED_LOCATION;
  }

  export const setLastFeedLocation = (
    pubIdOrSlug: string | null
  ): SetLastFeedLocation => ({
    pubIdOrSlug,
    type: ActionTypeKeys.SET_LAST_FEED_LOCATION,
  });

  export interface CompletePreLoginAction {
    readonly type: ActionTypeKeys.COMPLETE_PRE_LOGIN_ACTION;
  }

  export const completePreLoginAction = (): CompletePreLoginAction => ({
    type: ActionTypeKeys.COMPLETE_PRE_LOGIN_ACTION,
  });

  export interface PreLoginActionFailure {
    readonly type: ActionTypeKeys.PRE_LOGIN_ACTION_FAILURE;
    readonly error: string;
  }

  export const preLoginActionFailure = (error: string): PreLoginActionFailure => ({
    type: ActionTypeKeys.PRE_LOGIN_ACTION_FAILURE,
    error,
  });

  export interface SetCurrentLocation {
    readonly type: ActionTypeKeys.SET_CURRENT_LOCATION;
    readonly location: UserLocation;
    readonly source: LocationSource;
  }

  export const setCurrentLocation = (
    location: UserLocation,
    source: LocationSource
  ): SetCurrentLocation => ({
    type: ActionTypeKeys.SET_CURRENT_LOCATION,
    location,
    source,
  });

  export interface SetQuestionMetadata {
    readonly type: ActionTypeKeys.SET_QUESTION_METADATA;
    readonly questionMetadata: readonly QuestionMetadataEntry[];
    readonly questionMetadataSetId: ClientQuestionSetId | null;
  }

  export const setQuestionMetadata = (
    questionMetadata: readonly QuestionMetadataEntry[],
    questionMetadataSetId: ClientQuestionSetId | null
  ): SetQuestionMetadata => ({
    type: ActionTypeKeys.SET_QUESTION_METADATA,
    questionMetadata,
    questionMetadataSetId,
  });

  export interface SetQuestionMetadataItem {
    readonly type: ActionTypeKeys.SET_QUESTION_METADATA_ITEM;
    readonly setId: ClientQuestionSetId;
    readonly item: QuestionMetadataEntry;
  }

  export const setQuestionMetadataItem = (
    setId: ClientQuestionSetId,
    item: QuestionMetadataEntry
  ): SetQuestionMetadataItem => ({
    type: ActionTypeKeys.SET_QUESTION_METADATA_ITEM,
    setId,
    item,
  });

  export interface ClearQuestionMetadataItem {
    readonly type: ActionTypeKeys.CLEAR_QUESTION_METADATA_ITEM;
    readonly metadataType: string;
  }

  export const clearQuestionMetadataItem = (
    metadataType: string
  ): ClearQuestionMetadataItem => ({
    type: ActionTypeKeys.CLEAR_QUESTION_METADATA_ITEM,
    metadataType,
  });

  export const setMostRecentContentSetThatPromptedConversion = (
    id: ClientQuestionSetId
  ) => ({
    type: ActionTypeKeys.SET_MOST_RECENT_CONTENT_SET_THAT_PROMPTED_CONVERSION as const,
    questionSetId: id,
  });
}

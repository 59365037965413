import * as React from 'react';
import './styles.scss';
import {
  SavedSurveyItem,
  SurveyItemType,
  QuestionType,
  scrollIdForSurveyItem,
  RandomizedSurveyItems,
} from 'client/shared/core/question';
import { MaterialIcon, MaterialIconName } from 'client/shared/components/base';
import { VotingProps } from 'client/respondent/core/types';
import { wrap } from 'core';
import { FreeTextVote } from 'client/shared/components/free-text-vote';
import { MCVote } from 'client/shared/components/mc-vote';
import { PAVote } from 'client/shared/components/pa-vote';
import { GridVote, GridVoteMode } from 'client/shared/components/grid-vote';
import { defaultPadding } from '../util';
import { SurveyLoadedEvents } from 'client/shared/core/types';
import { Tooltip } from 'client/shared/components/tooltip';
import { isSurveyItemVisible } from 'client/respondent/core/vote-validator';
import { TextWithLineBreak } from 'client/shared/components/text-with-line-break';

type Question = Extract<SavedSurveyItem, { readonly type: SurveyItemType.QUESTION }>;

export interface SurveyQuestionProps {
  readonly question: Question;
  readonly questionCount: number;
  readonly events: SurveyLoadedEvents;
  readonly inProcessVotes: VotingProps.VotesByQuestionId;
  readonly voted: boolean;
  readonly previousVote: VotingProps.Vote | null;
  readonly showingSidebars: boolean;
  readonly hasInputError: boolean;
  readonly randomizedSurveyItems: readonly RandomizedSurveyItems[] | null;
}

const baseClass = 'pn-survey-response';

const copy = {
  demographicTooltip:
    "Decision makers can use this demographic information to better understand their results. You'll always remain anonymous and can edit your demographics in the future.",
};
export const SurveyQuestion: React.FC<SurveyQuestionProps> = (props) => {
  const {
    question,
    inProcessVotes,
    events,
    voted,
    previousVote,
    showingSidebars,
    hasInputError,
  } = props;

  const questionTitleClass = `font-size-sm font-weight-bold w-100 bg-white py-2 h-auto`;
  const inProcessVote = inProcessVotes[question.data.id] ?? null;
  const labelHtmlFor = `question-body-${question.data.id}`;

  const comments = wrap(() => {
    if (voted && previousVote?.type === QuestionType.MULTIPLE_CHOICE) {
      return previousVote.commentByChoice;
    }
    if (inProcessVote?.type === QuestionType.MULTIPLE_CHOICE) {
      return inProcessVote.commentByChoice;
    }
    return {};
  });

  const renderTitleContent = () => {
    return (
      <>
        <div className="d-flex">
          <TextWithLineBreak text={question.data.title} />
          <p className={`${baseClass}-required-text font-size-sm`}>
            {question.data.optional ? '' : '*'}
          </p>
        </div>
        {question.data.demographicAttribute?.id && (
          <Tooltip
            className={`${baseClass}-tooltip font-weight-normal`}
            content={copy.demographicTooltip}
            id={`demographicQuestion-${question.data.id}`}
            place="bottom"
          >
            <MaterialIcon
              className={`${baseClass}-tooltip-icon font-size-xs ml-1`}
              icon={MaterialIconName.INFO}
            />
          </Tooltip>
        )}
      </>
    );
  };

  return (
    <div
      className={`${baseClass} ${
        hasInputError ? 'border border-danger' : ''
      } ${defaultPadding}`}
      id={scrollIdForSurveyItem(question.data.id)}
    >
      <div
        className={`${baseClass}-question-title w-100 bg-white py-2 h-auto`}
        id={question.data.id}
      >
        {question.data.typedData.type === QuestionType.FREE_TEXT ? (
          <label
            className={questionTitleClass}
            htmlFor={`${labelHtmlFor}-form-control`}
          >
            {renderTitleContent()}
          </label>
        ) : (
          <div className={questionTitleClass}>{renderTitleContent()}</div>
        )}
      </div>
      {wrap(() => {
        switch (question.data.typedData.type) {
          case QuestionType.FREE_TEXT:
            return (
              <FreeTextVote
                ariaInvalid={hasInputError}
                disabled={voted}
                id={labelHtmlFor}
                inProcessComment={
                  voted && previousVote?.type === QuestionType.FREE_TEXT
                    ? previousVote.comment?.comment ?? ''
                    : inProcessVote?.comment?.comment ?? ''
                }
                updateCommentText={(comment) => {
                  events.surveyUpdateFreeTextComment(comment, question.data.id);
                }}
              />
            );
          case QuestionType.MULTIPLE_CHOICE:
            return (
              <MCVote
                ariaInvalid={hasInputError}
                choices={question.data.typedData.choices}
                commentByChoice={comments}
                disabled={voted}
                maxSelection={question.data.typedData.maxSelection}
                questionId={question.data.id}
                questionTitle={question.data.title}
                randomizeChoices={question.data.typedData.randomizeChoices}
                randomizedSurveyItems={props.randomizedSurveyItems}
                required={!question.data.optional}
                selectMultipleChoice={{
                  type: 'ACTION',
                  action: events.surveySelectMultipleChoice,
                }}
                selectedChoices={
                  voted && previousVote?.type === QuestionType.MULTIPLE_CHOICE
                    ? previousVote.choices
                    : inProcessVote?.type === QuestionType.MULTIPLE_CHOICE
                      ? inProcessVote.choices
                      : []
                }
                setRandomizedSurveyItems={events.setRandomizedSurveyItems}
                updateComment={events.surveyUpdateChoiceComment}
              />
            );
          case QuestionType.POINT_ALLOCATION:
            return (
              <PAVote
                choices={question.data.typedData.choices}
                disabled={voted}
                questionId={question.data.id}
                selectPointAllocation={events.surveySelectPointAllocation}
                selectedChoices={
                  voted && previousVote?.type === QuestionType.POINT_ALLOCATION
                    ? previousVote.choices
                    : inProcessVote?.type === QuestionType.POINT_ALLOCATION &&
                        inProcessVote.choices.length !== 0
                      ? inProcessVote.choices
                      : question.data.typedData.choices.map((ch) => ({
                          id: ch.id,
                          point: 0,
                        }))
                }
              />
            );
          case QuestionType.GRID_CHOICE:
            return (
              <GridVote
                disabled={voted}
                gridChoice={{
                  ...question.data.typedData,
                  rows: question.data.typedData.rows.filter((row) =>
                    isSurveyItemVisible(row.conditions, inProcessVotes)
                  ),
                }}
                gridVoteMode={
                  showingSidebars ? GridVoteMode.CHECKBOX : GridVoteMode.DROPDOWN
                }
                hasInputError={props.hasInputError}
                questionId={question.data.id}
                randomizedSurveyItems={props.randomizedSurveyItems}
                required={!question.data.optional}
                selectGridChoice={events.surveySelectGridChoice}
                selectedGridChoice={
                  voted && previousVote?.type === QuestionType.GRID_CHOICE
                    ? previousVote.gridChoiceByRowId
                    : inProcessVote?.type === QuestionType.GRID_CHOICE
                      ? inProcessVote.gridChoiceByRowId
                      : null
                }
                setRandomizedSurveyItems={events.setRandomizedSurveyItems}
                stickyTitle
              />
            );
        }
      })}
    </div>
  );
};
